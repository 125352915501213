e4.quickorder = {
    init: function () {
        if ( jQuery( '.js-e-quickorder' ).length === 1 ) {
            e4.require.js( e4.settings.require.typeahead, function () {
                e4.quickorder.bindEvents();
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-quickorder-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
            var jSuggestionFirst = jQuery( this ).find( '.js-e-quickorder-suggestions' ).find( '.tt-selectable' ).first();
            if ( jSuggestionFirst.length === 1 ) {
                jSuggestionFirst.trigger( 'click' );
            }
        } ).on( 'click', '.js-e-quickorder-submit', function () {
            jQuery( this.form ).triggerHandler( 'submit' );
        } );
        jQuery( '.js-e-cart' ).on( 'updated.e4.cart', function () {
            e4.quickorder.focus();
        } );
        jQuery( '.js-e-quickorder-input' ).on( 'keydown', function ( event ) {
            if ( event.keyCode === 13 ) {
                var jSuggestions = jQuery( event.target.form ).find( '.js-e-quickorder-suggestions' ),
                    jSuggestionActive = jSuggestions.find( '.tt-selectable' ).filter( '.active' );
                if ( jSuggestionActive.length === 0 && jSuggestions.css( 'display' ) === 'block' ) {
                    jQuery( this.form ).triggerHandler( 'submit' );
                }
            }
        } ).typeahead( {
            hint: true,
            highlight: true,
            minLength: 0,
            classNames: {
                wrapper: 'e-quickorder-typeahead-wrapper js-e-quickorder-typeahead-wrapper w-100',
                input: 'js-e-quickorder-input',
                menu: 'js-e-quickorder-suggestions e-quickorder-suggestions dropdown-menu w-100',
                suggestion: 'js-e-quickorder-suggestions-item dropdown-item',
                open: 'open',
                cursor: 'active',
                highlight: 'active'
            }
        }, {
            name: 'espresso',
            display: function ( suggestion ) {
                return suggestion.number + ' ' + suggestion.name;
            },
            limit: 8,
            async: true,
            source: function ( query, syncResults, asyncResults ) {
                e4.quickorder.getSuggestions( query, function ( products ) {
                    asyncResults( products );
                } );
            }
        } ).on( 'typeahead:autocomplete typeahead:select', function ( event, product ) { // typeahead:cursorchange
            if ( product !== null ) {
                e4.quickorder.addProduct( product );
                jQuery( this ).typeahead( 'close' );
            }
        } );
    },
    getSuggestions: function ( query, asyncResults ) {
        if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
            this.jqXHR.abort();
        }
        query = jQuery.trim( query );
        if ( query !== '' ) {
            this.jqXHR = jQuery.ajax( {
                method: 'GET',
                url: e4.settings.ecom.quickorder.url,
                data: { q: query },
                dataType: 'json'
            } );
            this.jqXHR.done( function ( data ) {
                asyncResults( data.products );
            } );
        }
    },
    focus: function () {
        var jQuickorder = jQuery( '.js-e-quickorder' ),
            intTarget = Math.ceil( jQuickorder.offset().top - jQuery( window ).height() + jQuickorder.height() + e4.scrollToAnchor.getNudge() );
        e4.scrollToAnchor.animate( intTarget, function () {
            if ( jQuery( document.activeElement ).is( '.js-e-orderline-quantity-input' ) !== true ) {
                jQuickorder.find( '.js-e-quickorder-input' ).focus();
            }
        } );
    },
    clear: function () {
        jQuery( '.js-e-quickorder-input' ).typeahead( 'val', '' );
    },
    addProduct: function ( objProduct ) {
        e4.cart.addProduct( {
            ProductID: objProduct.id,
            VariantID: objProduct.variantId
        } );
        e4.quickorder.clear();
    }
};
