e4.cc = {
    init: function () {
        if ( jQuery( '.js-e-favorite-list' ).length > 0 ) {
            e4.cc.favoritelists.bindEvents();
        }
    },
    favoritelists: {
            bindEvents: function () {
                var jList = jQuery( '.js-e-favorite-list' );
                jList.on( 'click', '.js-e-favorite-list-product-remove-link', function ( event ) {
                    event.preventDefault();
                    e4.cc.favoritelists.removeProduct( event.target );
                } );
                jList.on( 'click', '.js-e-favorite-list-product-add-to-cart-btn', function ( event ) {
                    e4.cc.favoritelists.addSingleProductToCart( event.target );
                } );
                jList.on( 'click', '.js-e-favorite-list-delete-link', e4.cc.favoritelists.delete );
            },
            removeProduct: function ( domLink ) {
                e4.cc.ajax( {
                    url: domLink.href + '&Redirect=false'
                }, function () {
                    jQuery( domLink ).closest( '.js-e-favorite-list-product' ).slideUp( function () {
                        jQuery( this ).closest( '.js-e-favorite-list' ).find( '.js-e-favorite-list-product-count' ).html( function ( intIndex, strOldHtml ) {
                            return parseInt( strOldHtml ) - 1;
                        } );
                    } );
                } );
            },
            addSingleProductToCart: function ( domInput ) {
                var jProduct = jQuery( domInput ).closest( '.js-e-favorite-list-product' );
                jProduct.closest( '.js-e-favorite-list-form' ).find( '.js-e-favorite-list-product' ).not( jProduct ).find( ':input' ).prop( 'disabled', true );
            },
            delete: function ( event ) {
                if ( confirm( jQuery( event.target ).data( 'confirm-msg' ) ) === false ) {
                    event.preventDefault();
                }
            }
        },
        ajax: function ( objAjaxSettings, fnCallback ) {
            var jqXHR = jQuery.ajax( jQuery.extend( {
                url: location.href,
                method: 'POST',
                dataType: 'html',
                cache: false
            }, objAjaxSettings ) );
            jqXHR.done( function () {
                if ( typeof fnCallback === 'function' ) {
                    fnCallback();
                }
            } );
            jqXHR.fail( function ( data, textStatus, errorThrown ) {
                console.error( data, textStatus, errorThrown );
            } );
    }
};
