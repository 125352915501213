//TODO: Lav inpage search form
e4.search = {
    init: function () {
        if ( jQuery( '.js-e-search' ).length > 0 ) {
            e4.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-search-result-item-product #js-e-handlebars-tmpl-search-result-item-page' );
                e4.search.event = e4.util.registerEvents( 'loaded', 'search' );
                e4.search.bindEvents();
                if ( e4.util.hash.get( 'search' ) !== undefined ) {
                    jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
                }
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-search-link' ).on( 'click', function ( event ) {
            event.preventDefault();
            if ( e4.util.hash.get( 'search' ) === undefined ) {
                e4.util.hash.set( 'search' );
            }
        } );
        jQuery( '.js-e-search-modal' ).on( 'shown.bs.modal', e4.search.onShow ).on( 'hide.bs.modal', e4.search.onShow );
        jQuery( '.js-e-search-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
            e4.search.result.get( jQuery( event.target ).find( '.js-e-search-input' ).get( 0 ) );
        } ).find( '.js-e-search-input' ).on( 'input', e4.util.debounce( function ( event ) {
            e4.search.toggleInputGroupState( event.target );
            jQuery( event.target ).closest( '.js-e-search-form' ).triggerHandler( 'submit' );
        }, e4.settings.debounceDelay.input ) ).on( 'change', function ( event ) {
            if ( event.target.value !== '' && e4.util.hash.get( 'search' ) !== undefined ) {
                e4.util.hash.replace( 'search', event.target.value );
            }
        } ).one( 'focus', e4.search.loadIndex );
        jQuery( '.js-e-search-clear' ).on( 'click', function ( event ) {
            e4.search.clearInput( event.target );
        } );
        jQuery( window ).on( 'hashchange', function () {
            var strHashSearch = e4.util.hash.get( 'search' );
            if ( strHashSearch === undefined ) {
                jQuery( '.js-e-search-modal' ).filter( '.show' ).modal( 'hide' );
            }
            else {
                jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
            }
        } );
    },
    loadIndex: function () {
        if ( e4.settings.search.result.products.show === true ) {
            jQuery.get( e4.settings.search.result.products.url + ( new Date() ).getTime() );
        }
        if ( e4.settings.search.result.pages.show === true ) {
            jQuery.get( e4.settings.search.result.pages.url + ( new Date() ).getTime() );
        }
    },
    onShow: function ( event ) {
        var jSearchInput = jQuery( event.target ).find( '.js-e-search-input' ),
            strQuery = jSearchInput.val(),
            strHashSearch = e4.util.hash.get( 'search' );
        if ( strHashSearch !== undefined && strHashSearch !== '' ) {
            jSearchInput.val( strHashSearch ).triggerHandler( 'input' );
        }
        else if ( strQuery !== '' ) {
            e4.util.hash.set( 'search', strQuery );
            jSearchInput.triggerHandler( 'input' );
        }
        else {
            e4.util.hash.set( 'search' );
        }
        jSearchInput.trigger( 'focus' );
    },
    onHide: function ( event ) {
        jQuery( event.target ).find( '.js-e-search-input' ).trigger( 'blur' );
        if ( e4.util.hash.get( 'search' ) !== undefined ) {
            e4.util.hash.remove( 'search' );
        }
    },
    toggleInputGroupState: function ( domNode ) {
        var jSearchInputGroup = jQuery( domNode ).closest( '.js-e-search-input-group' );
        if ( domNode.value === '' ) {
            jSearchInputGroup.addClass( 'is-empty' );
        }
        else {
            jSearchInputGroup.removeClass( 'is-empty' );
        }
    },
    clearInput: function ( domNode ) {
        jQuery( domNode ).closest( '.js-e-search-input-group' ).find( '.js-e-search-input' ).val( '' ).trigger( 'focus' )
            .triggerHandler( 'input' );
    },
    result: {
        get: function ( domNode ) {
            var jSearch = jQuery( domNode ).closest( '.js-e-search' ),
                arrPromises = [];
            jSearch.find( '.js-e-search-loading-overlay' ).addClass( 'is-loading' );
            if ( jQuery.trim( domNode.value ) !== '' ) {
                if ( e4.settings.search.result.products.show === true ) {
                    arrPromises.push( e4.search.result.products.get( domNode ) );
                }
                if ( e4.settings.search.result.pages.show === true ) {
                    arrPromises.push( e4.search.result.pages.get( domNode ) );
                }
            }
            else {
                if ( e4.settings.search.result.products.show === true ) {
                    arrPromises.push( e4.search.result.products.abort( domNode ) );
                }
                if ( e4.settings.search.result.pages.show === true ) {
                    arrPromises.push( e4.search.result.pages.abort( domNode ) );
                }
                jSearch.find( '.js-e-search-loading-overlay' ).removeClass( 'is-loading' );
            }
            return jQuery.when.apply( jQuery, arrPromises ).then( function () {
                e4.search.result.count = e4.search.result.products.count + e4.search.result.pages.count;
                jQuery( domNode ).closest( '.js-e-search-form' ).trigger( e4.search.event.loaded, {
                    query: jQuery.trim( domNode.value ),
                    resultCount: e4.search.result.count
                } );
                e4.search.result.show( domNode );
            }, function () {
                e4.search.result.hide( domNode );
            } );
        },
        show: function ( domNode ) {
            var jSearch = jQuery( domNode ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result-container' ).addClass( 'is-active' );
            jSearch.find( '.js-e-search-result-count' ).text( e4.search.result.count );
            jSearch.find( '.js-e-search-loading-overlay' ).removeClass( 'is-loading' );
        },
        hide: function ( domNode ) {
            var jSearch = jQuery( domNode ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result-container' ).removeClass( 'is-active' );
            e4.search.result.products.clear( domNode );
            e4.search.result.pages.clear( domNode );
        },
        products: {
            jqXhr: null,
            count: 0,
            get: function ( domNode ) {
                if ( e4.search.result.products.jqXhr !== null && e4.search.result.products.jqXhr.readyState !== 4 ) {
                    e4.search.result.products.jqXhr.abort();
                }
                e4.search.result.products.jqXhr = jQuery.ajax( {
                    url: e4.settings.search.result.products.url + encodeURIComponent( jQuery.trim( domNode.value ) ),
                    method: 'GET',
                    dataType: 'json',
                    cache: false
                } ).done( function ( jsonData ) {
                    e4.search.result.products.update( domNode, jsonData );
                } ).fail( function ( jqXhr, strTextStatus, objError ) {
                    if ( strTextStatus !== 'abort' ) {
                        console.error( objError );
                    }
                } );
                return e4.search.result.products.jqXhr;
            },
            abort: function () {
                if ( e4.search.result.products.jqXhr !== null && e4.search.result.products.jqXhr.readyState !== 4 ) {
                    return e4.search.result.products.jqXhr.abort();
                }
                return jQuery.Deferred().reject();
            },
            update: function ( domNode, jsonData ) {
                e4.search.result.products.count = jsonData.products.length;
                if ( e4.search.result.products.count > 0 ) {
                    var jSearch = jQuery( domNode ).closest( '.js-e-search' ),
                        intMaxResults = ( e4.settings.search.result.pages.show === true ) ? e4.settings.search.result.products.max[ e4.util.getBreakPoint() ] : 120,
                        jsonDataSliced = {
                            products: jsonData.products.slice( 0, intMaxResults )
                        };
                    jSearch.find( '.js-e-search-result-products' )
                        .html( e4.handlebars.tmpl.searchResultItemProduct( jsonDataSliced ) ).removeClass( 'is-empty' );
                    e4.search.result.products.toggleShowAllLink( domNode, jsonData, intMaxResults );
                }
                else {
                    e4.search.result.products.clear( domNode );
                }
            },
            clear: function ( domNode ) {
                jQuery( domNode ).closest( '.js-e-search' ).find( '.js-e-search-result-products' ).addClass( 'is-empty' )
                    .html( '' );
            },
            toggleShowAllLink: function ( domNode, jsonData, intMaxResults ) {
                var jShowAllLink = jQuery( domNode ).closest( '.js-e-search' ).find( '.js-e-search-products-show-all' );
                if ( e4.search.result.products.count > intMaxResults ) {
                    jShowAllLink.find( 'a' ).attr( 'href', e4.settings.search.result.products.showAllLink + domNode.value )
                        .on( 'click', {
                            'domNode': domNode,
                            'products': jsonData
                        }, e4.search.result.products.showAll );
                }
                else {
                    jShowAllLink.addClass( 'd-none' );
                    jShowAllLink.find( 'a' ).off( 'click', e4.search.result.products.showAll );
                }
            },
            showAll: function ( event ) {
                if ( e4.search.result.products.count > 0 && event.data !== undefined ) {
                    event.preventDefault();
                    var jSearch = jQuery( event.data.domNode ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-products' )
                        .html( e4.handlebars.tmpl.searchResultItemProduct( event.data.products ) ).removeClass( 'is-empty' );
                    jSearch.find( '.js-e-search-products-show-all' ).addClass( 'd-none' );
                }
            }
        },
        pages: {
            jqXhr: null,
            count: 0,
            get: function ( domNode ) {
                if ( e4.search.result.pages.jqXhr !== null && e4.search.result.pages.jqXhr.readyState !== 4 ) {
                    e4.search.result.pages.jqXhr.abort();
                }
                e4.search.result.pages.jqXhr = jQuery.ajax( {
                    url: e4.settings.search.result.pages.url + encodeURIComponent( jQuery.trim( domNode.value ) ),
                    method: 'GET',
                    dataType: 'json',
                    cache: false
                } ).done( function ( jsonData ) {
                    e4.search.result.pages.update( domNode, jsonData );
                } ).fail( function ( jqXhr, strTextStatus, objError ) {
                    if ( strTextStatus !== 'abort' ) {
                        console.error( objError );
                    }
                } );
                return e4.search.result.pages.jqXhr;
            },
            abort: function () {
                if ( e4.search.result.pages.jqXhr !== null && e4.search.result.pages.jqXhr.readyState !== 4 ) {
                    return e4.search.result.pages.jqXhr.abort();
                }
                return jQuery.Deferred().reject();
            },
            update: function ( domNode, jsonData ) {
                e4.search.result.pages.count = jsonData.pages.length;
                if ( e4.search.result.pages.count > 0 ) {
                    var jSearch = jQuery( domNode ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-pages' ).html( e4.handlebars.tmpl.searchResultItemPage( jsonData ) )
                        .removeClass( 'is-empty' );
                }
                else {
                    e4.search.result.pages.clear( domNode );
                }
            },
            clear: function ( domNode ) {
                jQuery( domNode ).closest( '.js-e-search' ).find( '.js-e-search-result-pages' ).addClass( 'is-empty' ).html( '' );
            }
        }
    }
};
