e4.require = {
    debug: false,
    init: function () {
        var jRequire = jQuery( '.js-e-require' ),
            strDesignFolder = jQuery( 'body' ).data( 'design' );
        if ( jRequire.length > 0 ) {
            jRequire.each( function ( intIndex, domNode ) {
                var strRequireNamespaces = jQuery( domNode ).data( 'require' );
                if ( strRequireNamespaces !== undefined ) {
                    jQuery.each( strRequireNamespaces.split( ' ' ), function ( intIndex, strNamespace ) {
                        if ( e4.settings.require.namespace[ strNamespace ] !== undefined ) {
                            // TODO: Refactor this!
                            e4.settings.require.namespace[ strNamespace ].url = [
                                '/files/templates/designs/',
                                strDesignFolder,
                                '/_assets/_dist/js/',
                                e4.settings.require.namespace[ strNamespace ].fileName,
                                '.js'
                            ].join( '' );
                            e4.util.debug( [
                                'Requiring local e4 namespace ',
                                e4.settings.require.namespace[ strNamespace ]
                            ], e4.require.debug );
                            e4.require.js( e4.settings.require.namespace[ strNamespace ] );
                        }
                    } );
                }
            } );
        }
    },
    ajax: function ( strUrl ) {
        if ( strUrl !== undefined && strUrl !== null && strUrl !== '' ) {
            return jQuery.ajax( {
                url: strUrl,
                cache: true,
                timeout: e4.settings.require.timeout,
                dataType: 'script'
            } );
        }
        else {
            return jQuery.Deferred().reject();
        }
    },
    js: function ( objScript, fnCallback, arrCallbackArgs ) {
        if ( objScript.promise === undefined || objScript.promise.state() === 'rejected' ) {
            e4.util.debug( [
                'Requiring script ',
                objScript
            ], e4.require.debug );
            objScript.promise = jQuery.when( e4.require.ajax( objScript.url ) ).then( null, function () {
                e4.util.debug( [
                    'Requiring script from fallback ',
                    objScript
                ], e4.require.debug );
                return e4.require.ajax( objScript.fallback );
            } ).then( function () {
                e4.util.debug( [
                    'Done requiring script ',
                    objScript
                ], e4.require.debug );
                if ( typeof objScript.callback === 'function' ) {
                    objScript.callback.apply( objScript );
                }
            }, function () {
                e4.util.debug( [
                    'Failed to require script ',
                    objScript
                ], e4.require.debug );
            } );
        }
        if ( typeof fnCallback === 'function' ) {
            objScript.promise.done( function () {
                fnCallback.apply( window, arrCallbackArgs );
            } );
        }
        return objScript.promise;
    }
};
