var e4 = e4 || {},
    biodiversitet = biodiversitet || {};
(function () {
    'use strict';
    jQuery(function () {
        biodiversitet.init();
    });
    biodiversitet.init = function () {
        biodiversitet.hamburgerNavigation.init();
    };

    biodiversitet.hamburgerNavigation = {
        init: function () {
            
        }
    };

}());