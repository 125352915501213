e4.product = {
    debug: false,
    init: function () {
        if ( jQuery( '.js-e-product-form, .js-e-productlist' ).length > 0 ) {
            e4.require.js( e4.settings.require.jQueryValidate, function () {
                e4.product.bindEvents();
                // Variants is not yet ready for production.
                // e4.product.variants.init();
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
            e4.product.validateForm( domForm );
        } );
        jQuery( '.js-e-productlist' ).on( 'builded.e4.productlist', function ( event ) {
            jQuery( event.target ).find( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
                e4.product.validateForm( domForm );
            } );
        } );
        if ( jQuery( '.js-e-product-favorite-list-form' ).length > 0 ) {
            e4.product.favoritelist.bindEvents();
        }
    },
    validateForm: function ( domForm ) {
        var jForm = jQuery( domForm );
        jForm.validate( {
            submitHandler: function ( domForm ) {
                if ( jForm.is( '.js-e-product-form-removefromcart' ) === true ) {
                    e4.product.removeFromCart( domForm );
                }
                else /* if ( jForm.is( '.js-e-product-form-addtocart' ) === true )*/ {
                    e4.product.addToCart( domForm );
                }
            }
        } );
    },
    addToCart: function ( domForm ) {
        var jForm = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.addProduct( objFormData );
        }
        else {
            e4.cart.addProduct( objFormData );
        }
    },
    removeFromCart: function ( domForm ) {
        var jForm = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.removeProduct( objFormData );
        }
        else {
            e4.cart.removeProduct( objFormData );
        }
    },
    favoritelist: {
        bindEvents: function () {
            jQuery( '.js-e-product-favorite-list-form' ).on( 'submit', function ( event ) {
                event.preventDefault();
            } ).on( 'change', '.js-e-favorite-list', function ( event ) {
                e4.product.favoritelist.toggleList( event.target );
            } );
        },
        toggleList: function ( domInput ) {
            var jInput = jQuery( domInput );
            if ( jInput.prop( 'checked' ) === true ) {
                e4.product.favoritelist.addToList( jInput );
            }
            else {
                e4.product.favoritelist.removeFromList( jInput );
            }
        },
        addToList: function ( jInput ) {
            jInput.prop( 'disabled', true );
            e4.cc.ajax( {
                url: jInput.data( 'url-add' ) + '&Redirect=false'
            }, function () {
                jInput.prop( 'disabled', false );
            } );
        },
        removeFromList: function ( jInput ) {
            jInput.prop( 'disabled', true );
            e4.cc.ajax( {
                url: jInput.data( 'url-remove' ) + '&Redirect=false'
            }, function () {
                jInput.prop( 'disabled', false );
            } );
        }
    }
};
