e4.login = {
    debug: false,
    init: function () {
        // ATTENTION: Please note that all events are triggered on jQuery( document ) and not the login form.
        e4.login.event = e4.util.registerEvents( 'userauthenticated', 'login' );
        e4.login.checkUserAuthentication();
        e4.login.bindEvents();
    },
    bindEvents: function () {
        jQuery( '.js-e-login-remember' ).on( 'change', function ( event ) {
            e4.login.toggleRememberUser( event.target );
        } ).triggerHandler( 'change' );
    },
    checkUserAuthentication: function () {
        e4.util.debug( [
            'checkUserAuthentication',
            jQuery( 'body' ).data( 'isUserAuthenticated' ),
            sessionStorage.getItem( 'e4.login.isUserAuthenticated' ),
        ], e4.login.debug );
        // Check to see if user is authenticated
        if ( jQuery( 'body' ).data( 'isUserAuthenticated' ) === true ) {
            // Check to see if knew that already
            if ( sessionStorage.getItem( 'e4.login.isUserAuthenticated' ) !== 'true' ) {
                // And if we didn't, we can trigger login event
                jQuery( document ).trigger( e4.login.event.userauthenticated );
                e4.util.debug( [
                    'checkUserAuthentication event',
                    e4.login.event.userauthenticated
                ], e4.login.debug );
            }
            // Make sure that we remember this for next page load
            sessionStorage.setItem( 'e4.login.isUserAuthenticated', 'true' );
        }
        else {
            // Invalidate info if user logs off.
            sessionStorage.setItem( 'e4.login.isUserAuthenticated', 'false' );
        }
    },
    toggleRememberUser: function ( domInput ) {
        jQuery( domInput.form ).find( '.js-e-login-remember-username, .js-e-login-remember-password' ).val( ( jQuery( domInput ).prop( 'checked' ) === true ? 'True' : '' ) );
    }
};
